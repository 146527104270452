import Select, { SelectProps } from 'components/atoms/Select';
import { useAppConfig } from 'components/organisms/App';
import { useMemo } from 'react';

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
    value?: Region;
    onChange?: (newValue: Region | undefined) => void;
}

const RegionSelect: React.FC<Props> = ({ value, onChange, ...rest }) => {
    const regions = useAppConfig().regions;
    const options = useMemo(() => {
        if (!regions) return [];

        return regions.map(r => ({
            key: r.name,
            label: r.name,
            value: r
        }));
    }, [regions]);

    return (
        <Select
            options={options}
            value={options.find(v => v.key === value?.name)}
            onChange={v => onChange?.(v?.value)}
            {...rest}
        />
    );
};

export default RegionSelect;
