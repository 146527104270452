import { AppFilter } from 'components/organisms/App';
import useSWR from 'swr';

const fetcher = async <T>(url: string) => {
    const res = await fetch(url);
    const data = await res.json();

    if (!res.ok) {
        if (data?.error != null) {
            throw new Error(data.error);
        } else {
            throw new Error('Could not fetch data');
        }
    }

    return data as T;
};

export const useConfig = () => {
    return useSWR<APIConfig, Error>(`/api/config`, fetcher);
};

export const useResults = (filter: AppFilter) => {
    let url: string | boolean = false;
    if (filter.query || (filter.partnerType && filter.region)) {
        url = `/api/brand_partners?`;
        if (filter.query) {
            url += `query=${encodeURIComponent(filter.query)}`;
        } else {
            url += [
                filter.partnerSpecialization
                    ? `specialization=${encodeURIComponent(filter.partnerSpecialization.key)}`
                    : undefined,
                filter.partnerType ? `type=${encodeURIComponent(filter.partnerType.key)}` : undefined,
                filter.region ? `region=${encodeURIComponent(filter.region.name)}` : undefined,
                filter.municipality ? `municipality=${encodeURIComponent(filter.municipality.name)}` : undefined
            ]
                .filter(Boolean)
                .join('&');
        }
    }

    return useSWR<APIResultItem[], Error>(url, fetcher);
};