import cx from 'classnames';
import { useAppConfig } from 'components/organisms/App';
import isPresent from 'lib/isPresent';
import PartnerMap from './PartnerMap';

interface IProps {
    item: APIResultItem;
    className?: string;
}

const PartnerDetail = ({ item, className }: IProps) => {
    const { certifications, types } = useAppConfig().brand.partner;
    return (
        <div className={cx('text-sm', className)}>
            <h4 className="text-lg font-bold mb-4">{item.partner.title}</h4>
            <div className="flex flex-col space-y-2 desktop:space-y-0 desktop:flex-row">
                <div className="flex-1 flex flex-col tablet:grid grid-cols-2 gap-x-2 gap-y-4">
                    <div className="col-start-1 col-end-1 row-start-1 row-end-1">
                        <strong>Adresa</strong>
                        <p>
                            {item.partner.address}
                            <br />
                            {item.partner.city}, {item.partner.post_code}
                        </p>
                    </div>
                    <div className="col-start-1 col-end-1 row-start-2 row-end-2">
                        <strong>Kontakt</strong>
                        <Contact item={item} />
                    </div>
                    {item.types.length > 0 && (
                        <div className="col-start-2 col-end-2 row-start-1 row-end-1">
                            <strong>Typ partnera</strong>
                            <ul>
                                {item.types
                                    .map(type => types.find(t => t.key === type))
                                    .filter(isPresent)
                                    .map(type => (
                                        <li key={type.key}>{type.title}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                    {item.certifications.length > 0 && (
                        <div className="col-start-2 col-end-2 row-start-2 row-end-2">
                            <strong>Špecializácie</strong>
                            <ul>
                                {item.certifications
                                    .map(cert => certifications.find(c => c.key === cert))
                                    .filter(isPresent)
                                    .map(cert => (
                                        <li key={cert?.key}>{cert?.title}</li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div className="flex-1">
                    <PartnerMap item={item} />
                </div>
            </div>
        </div>
    );
};

const Contact = ({ item }: { item: APIResultItem }) => (
    <div>
        {item.partner.phone && (
            <div>
                Telefón:{' '}
                <a className="text-brand hover:underline" href={`tel:${item.partner.phone}`}>
                    {item.partner.phone}
                </a>
            </div>
        )}
        {item.partner.mobile && (
            <div>
                Mobil:{' '}
                <a className="text-brand hover:underline" href={`tel:${item.partner.mobile}`}>
                    {item.partner.mobile}
                </a>
            </div>
        )}
        {item.partner.email && (
            <div>
                Email:{' '}
                <a className="text-brand hover:underline" href={`mailto:${item.partner.email}`}>
                    {item.partner.email}
                </a>
            </div>
        )}
        {item.partner.web && (
            <div>
                Web:{' '}
                <a className="text-brand hover:underline" href={item.partner.web} target="_blank">
                    {item.partner.web}
                </a>
            </div>
        )}
    </div>
);

export default PartnerDetail;
