import React, { useEffect } from 'react';

export const useClickOutside = (ref: React.RefObject<HTMLElement>, callback: (e: MouseEvent) => void) => {
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (ref.current == null || e.target == null || !ref.current.contains(e.target as HTMLElement)) {
                callback(e);
            }
        };
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, [callback]);
};
