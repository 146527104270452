import cx from 'classnames';
import Check from './Check';

export interface SelectOption {
    label: string;
    note?: string;
    key: string;
}

export interface SelectProps<T extends SelectOption = any> {
    options?: T[];
    value?: T;
    onChange?: (newValue: T | undefined) => void;
    label?: string;
    className?: string;
    disabled?: boolean;
    open?: boolean;
    onToggle?: (open?: boolean) => void;
}

export interface SelectHandle {
    focus: () => void;
}

const Select = <T extends SelectOption>({
    options,
    value,
    onChange,
    className,
    label,
    disabled,
    open,
    onToggle
}: SelectProps<T>) => {
    const handleSelect = (option: T) => {
        onChange?.(option);
    };

    return (
        <div className={cx('relative', className)}>
            <button
                className={cx(
                    'border border-brandBorder rounded-lg cursor-pointer pl-3 pr-12 py-2 w-full flex justify-between items-center',
                    {
                        '': !disabled,
                        'pointer-events-none text-brandBorder': disabled
                    }
                )}
                onClick={() => onToggle?.()}
                disabled={disabled}
            >
                {label}
                <svg
                    className={cx('icon absolute right-2 w-8 h-8', {
                        'rotate-180': open,
                        'text-brand': !disabled,
                        'text-brandBorder': disabled
                    })}
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                >
                    <path d="m7 10 5 5 5-5H7z" />
                </svg>
            </button>
            <ul
                className={cx(
                    'absolute left-0 p-3 space-y-2 border border-brandBorder rounded-lg bg-white z-10 list-none',
                    {
                        hidden: !open
                    }
                )}
                style={{ top: 'calc(100% + 0.15em)' }}
            >
                {options?.map(option => (
                    <li
                        className="cursor-pointer flex items-center space-x-3 group whitespace-nowrap"
                        key={option.key}
                        value={option.key}
                        onClick={e => {
                            handleSelect(option);
                            e.stopPropagation();
                        }}
                    >
                        <Check checked={value?.key === option.key} />
                        <div>
                            <div>{option.label}</div>
                            {option.note && <div className="text-xs text-gray-500">{option.note}</div>}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Select;
