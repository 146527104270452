import PartnerDetail from 'components/molecules/PartnerDetail';
import PartnersMap from 'components/molecules/PartnersMap';
import ResultItem from 'components/molecules/ResultItem';
import { useResults } from 'lib/api';
import * as React from 'react';
import { AppFilter } from './App';

interface Props {
    filter: AppFilter;
}

const Results: React.FC<Props> = ({ filter }) => {
    const { data, error } = useResults(filter);
    const [selectedItem, setSelectedItem] = React.useState<APIResultItem>();

    return (
        <React.Fragment>
            <div className="mt-4">
                <PartnersMap items={data ?? []} onSelect={setSelectedItem} />
            </div>
            {selectedItem && (
                <div className="py-5 border-b border-brandBorder">
                    <PartnerDetail className="px-10 tablet:px-0" item={selectedItem} />
                </div>
            )}
            <div className="px-10 py-5">
                <h3 className="font-bold">
                    {filter.query
                        ? 'Zoznam partnerov zodpovedajúcich vyhľadávaniu'
                        : 'Zoznam partnerov podľa vybraného filtra'}
                </h3>
            </div>
            {error && <div>{error.message}</div>}
            <table className="w-full text-sm">
                <thead>
                    <tr className="text-left">
                        <th className="bg-brandBackground py-5 pl-10">Firma</th>
                        <th className="bg-brandBackground py-5 hidden tablet:table-cell">Mesto</th>
                        <th className="bg-brandBackground pr-2 hidden tablet:table-cell tablet:pr-10">Kontakt</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map(item => (
                        <ResultItem key={item.id} item={item} />
                    ))}
                </tbody>
            </table>
        </React.Fragment>
    );
};

export default Results;
