import cx from 'classnames';
import * as React from 'react';

interface Props {
    className?: string;
    onRemove?: () => void;
    children?: React.ReactNode;
}

const Pill = ({ className, children, onRemove }: Props) => (
    <div
        className={cx('flex items-center space-x-2 cursor-pointer hover:opacity-60', className)}
        onClick={() => onRemove?.()}
    >
        <svg
            className="icon text-brand w-6 h-6"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="HighlightOffOutlinedIcon"
        >
            <path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
        </svg>
        <div>{children}</div>
    </div>
);

export default Pill;
