import { useAppConfig } from 'components/organisms/App';
import * as React from 'react';
import MapWrapper from './MapWrapper';

interface IProps {
    item: APIResultItem;
}

const PartnersMap = ({ item }: IProps) => {
    const fillColor = useAppConfig().brand.primary_color;
    const mapContainerRef = React.useRef(null);
    const mapRef = React.useRef<google.maps.Map>();

    React.useEffect(() => {
        if (!mapContainerRef.current) return;
        if (!item.partner.latitude || !item.partner.longitude) return;

        const position = { lat: Number(item.partner.latitude), lng: Number(item.partner.longitude) };

        mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
            zoom: 16,
            center: position,
            scrollwheel: false
        });

        const svgMarker = {
            path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: 'black',
            rotation: 0,
            scale: 2,
            anchor: new google.maps.Point(15, 30)
        };

        new google.maps.Marker({
            position,
            icon: {
                ...svgMarker,
                fillColor
            },
            map: mapRef.current,
            clickable: false
        });
    }, []);

    return <div className="h-64" ref={mapContainerRef} />;
};

export default (props: IProps) => (
    <MapWrapper>
        <PartnersMap {...props} />
    </MapWrapper>
);
