import cx from 'classnames';
import * as React from 'react';
import PartnerDetail from './PartnerDetail';

interface IProps {
    item: APIResultItem;
}

const ResultItem = ({ item }: IProps) => {
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <tr className="hover:text-brand cursor-pointer border-b border-brandBorder" onClick={() => setOpen(!open)}>
                <td className="pl-10 py-5 tablet:py-0">
                    <div className="inline-flex relative items-center">
                        <svg
                            className={cx('icon absolute right-full text-brand w-8 h-8', {
                                '-rotate-90': !open
                            })}
                            focusable="false"
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                        >
                            <path d="m7 10 5 5 5-5H7z" />
                        </svg>
                        <span className="font-bold tablet:font-normal">{item.partner.title}</span>
                    </div>
                    <div className="tablet:hidden">
                        {item.partner.city}
                        <br />
                        <Contact item={item} />
                    </div>
                </td>
                <td className="hidden tablet:table-cell py-5">{item.partner.city}</td>
                <td className="hidden tablet:table-cell text-xs">
                    <Contact item={item} />
                </td>
            </tr>
            {open && (
                <tr>
                    <td colSpan={3} className="p-10 pt-5 border-b border-brandBorder">
                        <PartnerDetail item={item} />
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
};

const Contact = ({ item }: { item: APIResultItem }) => (
    <>
        {item.partner.phone && (
            <>
                <span className="inline-block w-10">Tel:</span>
                <span>{item.partner.phone}</span>
                <br />
            </>
        )}
        {item.partner.mobile && (
            <>
                <span className="inline-block w-10">Mob:</span>
                <span>{item.partner.mobile}</span>
                <br />
            </>
        )}
        {item.partner.email && (
            <>
                <span className="inline-block w-10">Email:</span>
                <span>{item.partner.email}</span>
                <br />
            </>
        )}
        {item.partner.web && (
            <>
                <span className="inline-block w-10">Web:</span>
                <span>{item.partner.web}</span>
                <br />
            </>
        )}
    </>
);

export default ResultItem;
