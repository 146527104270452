import { useConfig } from 'lib/api';
import { createContext, useContext, useEffect, useState } from 'react';
import FilterForm from './FilterForm';
import Results from './Results';

export type AppFilter = {
    query?: string;
    partnerSpecialization?: PartnerSpecialization;
    partnerType?: PartnerType;
    region?: Region;
    municipality?: Municipality;
};

const AppContext = createContext<APIConfig>({
    brand: { name: '', primary_color: '', ga_tracking_code: undefined, partner: { certifications: [], specializations: [], types: [] }, },
    regions: []
});
export const useAppConfig = () => useContext(AppContext);

const App = () => {
    const { data, error } = useConfig();
    const GTMCode = data?.brand.ga_tracking_code;

    const [filter, setFilter] = useState<AppFilter>({});

    useEffect(() => {
        window.vgskDataLayer = window.vgskDataLayer || [];
        window.vgskGTag = function () {
            window.vgskDataLayer.push(arguments);
        };
        window.vgskGTag('js', new Date());
    }, []);

    useEffect(() => {
        if (GTMCode) {
            window.vgskGTag('config', GTMCode);
            const script = document.createElement('script');
            script.id = 'vgskGTM';
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GTMCode}&l=vgskDataLayer`;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.getElementById(script.id)?.remove();
            };
        }
    }, [GTMCode]);

    return (
        <>
            {!data && !error && <p>Loading...</p>}
            {!data && error && <pre>{JSON.stringify(error, null, 2)}</pre>}
            {data && (
                <AppContext.Provider value={data}>
                    <style>
                        {`
:root {
    --mapa-partnerov-brand-color: ${data.brand.primary_color};
}
                    `}
                    </style>
                    <FilterForm initialFilter={filter} setFilter={setFilter} />
                    <Results filter={filter} />
                </AppContext.Provider>
            )}
        </>
    );
};

export default App;
