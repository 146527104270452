import cx from 'classnames';

interface IProps {
    checked?: boolean;
    className?: string;
}

const Check = ({ checked, className }: IProps) => (
    <div className={cx('border border-brandBorder rounded-[4px] h-5 w-5 flex items-center justify-center', className)}>
        <div
            className={cx('rounded-full bg-brand w-2 h-2 transition-opacity duration-100', {
                'opacity-0 group-hover:opacity-10': !checked,
                'opacity-100': checked
            })}
        />
    </div>
);

export default Check;
