import Select, { SelectOption, SelectProps } from 'components/atoms/Select';
import { useAppConfig } from 'components/organisms/App';
import { useMemo } from 'react';

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
    value?: PartnerType;
    onChange?: (newValue: PartnerType | undefined) => void;
}

const PartnerTypeSelect: React.FC<Props> = ({ value, onChange, ...rest }) => {
    const partnerTypes = useAppConfig().brand.partner.types;
    const options = useMemo<SelectOption[]>(
        () => partnerTypes.map(({ key, title, subtitle }) => ({ key, label: title, note: subtitle })),
        [partnerTypes]
    );

    return (
        <Select
            options={options}
            value={options.find(v => v.key === value?.key)}
            onChange={v => onChange?.(partnerTypes.find(t => t.key === v?.key) ?? undefined)}
            {...rest}
        />
    );
};

export default PartnerTypeSelect;
