import * as React from 'react';
import isPresent from 'lib/isPresent';
import MapWrapper from './MapWrapper';
import { useAppConfig } from 'components/organisms/App';

interface IProps {
    items: APIResultItem[];
    onSelect?: (item: APIResultItem | undefined) => void;
}

interface Marker {
    id: string;
    mapMarker: google.maps.Marker;
}

const PartnersMap = ({ items, onSelect }: IProps) => {
    const fillColor = useAppConfig().brand.primary_color;
    const mapContainerRef = React.useRef(null);
    const mapRef = React.useRef<google.maps.Map>();
    const markersRef = React.useRef<Marker[]>([]);
    const infoWindowsRef = React.useRef<google.maps.InfoWindow[]>([]);

    React.useEffect(() => {
        if (!mapContainerRef.current) return;

        mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
            center: new google.maps.LatLng(48.66813, 19.693601),
            zoom: 7,
            scrollwheel: false
        });
    }, []);

    React.useEffect(() => {
        if (!mapRef.current) return;

        const ids = items.map(i => i.id);
        const markers = markersRef.current;

        markers
            .filter(m => !ids.includes(m.id))
            .forEach(m => {
                m.mapMarker.setMap(null);
            });

        const svgMarker = {
            path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
            fillOpacity: 1,
            strokeWeight: 1,
            strokeColor: 'black',
            rotation: 0,
            scale: 2,
            anchor: new google.maps.Point(15, 30)
        };

        const bounds = new google.maps.LatLngBounds();
        markersRef.current = items
            .map(item => {
                if (!item.partner.latitude || !item.partner.longitude) return;
                const position = { lat: Number(item.partner.latitude), lng: Number(item.partner.longitude) };
                bounds.extend(position);

                const existingMarker = markers.find(m => m.id === item.id);
                if (existingMarker) return existingMarker;

                const newMarker = {
                    id: item.id,
                    mapMarker: new google.maps.Marker({
                        position,
                        icon: {
                            ...svgMarker,
                            fillColor
                        },
                        map: mapRef.current,
                        clickable: true
                    })
                };

                const contentString = `
<div class="infoWindow">
    <h1>${item.partner.title}</h1>
    <p>
        ${item.partner.address}<br />
        ${item.partner.city}
    </p>
    <p>
    ${item.partner.phone ? `Tel: ${item.partner.phone}<br />` : ''}
    ${item.partner.mobile ? `Mobil: ${item.partner.mobile}<br />` : ''}
    ${item.partner.email ? `Email: ${item.partner.email}<br />` : ''}
    </p>
    ${item.partner.web ? `<p>Web: ${item.partner.web}</p>` : ''}
</div>
`;
                const infoWindow = new google.maps.InfoWindow({
                    content: contentString
                });
                infoWindow.addListener('closeclick', () => {
                    onSelect?.(undefined);
                });
                infoWindowsRef.current.push(infoWindow);

                newMarker.mapMarker.addListener('click', () => {
                    infoWindowsRef.current.forEach(w => {
                        w.close();
                    });
                    infoWindow.open(mapRef.current, newMarker.mapMarker);
                    onSelect?.(item);
                });

                return newMarker;
            })
            .filter(isPresent);

        if (markersRef.current.length > 0) {
            mapRef.current.fitBounds(bounds);
        }
    }, [items]);

    return <div className="h-96" ref={mapContainerRef} />;
};

export default (props: IProps) => (
    <MapWrapper>
        <PartnersMap {...props} />
    </MapWrapper>
);
