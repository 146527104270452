import * as React from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

interface IProps {
    children?: React.ReactNode;
}

const MapWrapper = ({ children }: IProps) => {
  const metaTag = document.querySelector('meta[name="google-maps-api-key"]');
  const apiKey = metaTag && ((metaTag as HTMLMetaElement).content) || '';
  return (
    <Wrapper apiKey={apiKey} render={render}>
      {children}
    </Wrapper>
  );
};

const render = (status: Status): React.ReactElement => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return <></>;
};

export default MapWrapper;
