import cx from 'classnames';
import Pill from 'components/atoms/Pill';
import MunicipalitySelect from 'components/molecules/MunicipalitySelect';
import PartnerTypeSelect from 'components/molecules/PartnerTypeSelect';
import RegionSelect from 'components/molecules/RegionSelect';
import SpecializationSelect from 'components/molecules/SpecializationSelect';
import { useClickOutside } from 'lib/useClickOutside';
import { useRef, useState } from 'react';
import { AppFilter } from './App';

interface Props {
    initialFilter: AppFilter;
    setFilter: React.Dispatch<React.SetStateAction<AppFilter>>;
}

type Field = 'partnerSpecialization' | 'partnerType' | 'region' | 'municipality';

const FilterForm: React.FC<Props> = ({ initialFilter = {}, setFilter }) => {
    const selectsWrapperRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<Field | null>(null);
    const [state, setState] = useState<AppFilter>(initialFilter);

    useClickOutside(selectsWrapperRef, () => setOpen(null));

    const handleChange = <T extends keyof AppFilter>(key: T, value: AppFilter[T]) => {
        const newState: AppFilter = { ...state, [key]: value, query: undefined };

        let newOpen: Field | null = null;

        if (key === 'partnerType' && newState.partnerType == null) {
            newState.region = undefined;
            newState.municipality = undefined;
        }

        if (key === 'partnerType' && newState.partnerType != null && state.region == null) {
            newOpen = 'region';
        }

        if (key === 'partnerType' && newState.partnerType?.enable_specializations === false) {
            newState.partnerSpecialization = undefined;
        }

        if (newState.region !== state.region) {
            newState.municipality = undefined;
        }

        if (key === 'region' && newState.region != null && newState.municipality == null) {
            newOpen = 'municipality';
        }

        setState(newState);
        setOpen(newOpen);

        if (newState.partnerType && newState.region) {
            setFilter({ ...newState, query: undefined });
        } else {
            setFilter({});
        }
    };

    const handleSubmit = () => {
        setFilter({ query: state.query });
    };

    const handleToggle = (field: Field, o?: boolean) => {
        if (o !== undefined) {
            setOpen(o === true ? field : null);
        } else {
            setOpen(open === field ? null : field);
        }
    };

    return (
        <>
            <div className="flex justify-between flex-col space-y-2 desktop:space-y-0 desktop:space-x-2 desktop:flex-row">
                <div
                    className="flex flex-col space-y-2 tablet:flex-row tablet:space-x-2 tablet:space-y-0"
                    ref={selectsWrapperRef}
                >
                    <div>
                        <PartnerTypeSelect
                            label="Typ partnera"
                            value={state.partnerType}
                            onChange={v => handleChange('partnerType', v)}
                            open={open === 'partnerType'}
                            onToggle={o => handleToggle('partnerType', o)}
                        />
                    </div>
                    <div>
                        <RegionSelect
                            label="Vyberte kraj"
                            value={state.region}
                            onChange={v => handleChange('region', v)}
                            disabled={state.partnerType == null}
                            open={open === 'region'}
                            onToggle={o => handleToggle('region', o)}
                        />
                    </div>
                    <div>
                        <MunicipalitySelect
                            label="Vyberte okres"
                            value={state.municipality}
                            onChange={v => handleChange('municipality', v)}
                            region={state.region}
                            disabled={state.region == null}
                            open={open === 'municipality'}
                            onToggle={o => handleToggle('municipality', o)}
                        />
                    </div>
                    <div>
                        <SpecializationSelect
                            disabled={state.partnerType?.enable_specializations !== true}
                            label="Špecializácia partnera"
                            value={state.partnerSpecialization}
                            onChange={v => handleChange('partnerSpecialization', v)}
                            open={open === 'partnerSpecialization'}
                            onToggle={o => handleToggle('partnerSpecialization', o)}
                        />
                    </div>
                </div>
                <div>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <div className="relative flex items-center">
                            <input
                                className="border border-brandBorder rounded-lg cursor-pointer pl-3 pr-12 py-2 w-full flex justify-between items-center outline-brand"
                                value={state.query ?? ''}
                                onChange={e => setState({ query: e.target.value })}
                            />
                            <svg
                                className={cx('icon absolute right-2 w-8 h-8 text-brand cursor-pointer')}
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                onClick={() => handleSubmit()}
                            >
                                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                            </svg>
                        </div>
                    </form>
                </div>
            </div>
            <hr className="border-brand my-4" />
            <div className="flex flex-col tablet:flex-row space-y-2 tablet:space-y-0 tablet:space-x-4 my-4">
                {state.partnerType != null && (
                    <Pill onRemove={() => handleChange('partnerType', undefined)}>{state.partnerType.title}</Pill>
                )}
                {state.region != null && (
                    <Pill onRemove={() => handleChange('region', undefined)}>{state.region.name}</Pill>
                )}
                {state.municipality != null && (
                    <Pill onRemove={() => handleChange('municipality', undefined)}>{state.municipality.name}</Pill>
                )}
                {state.partnerSpecialization != null && (
                    <Pill onRemove={() => handleChange('partnerSpecialization', undefined)}>
                        {state.partnerSpecialization.title}
                    </Pill>
                )}
            </div>
        </>
    );
};

export default FilterForm;
