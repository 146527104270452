import Select, { SelectProps } from 'components/atoms/Select';
import { useMemo } from 'react';

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
    value?: Municipality;
    onChange?: (newValue: Municipality | undefined) => void;
    region?: Region;
}

const MunicipalitySelect: React.FC<Props> = ({ region, value, onChange, ...rest }) => {
    const options = useMemo(() => {
        if (region == null) return [];
        return region.municipalities.map(m => ({ key: m.name, label: m.name, value: m }));
    }, [region]);

    return (
        <Select
            options={options}
            value={options.find(v => v.key === value?.name)}
            onChange={v => onChange?.(v?.value)}
            {...rest}
        />
    );
};

export default MunicipalitySelect;
