import Select, { SelectProps } from 'components/atoms/Select';
import { useAppConfig } from 'components/organisms/App';
import { useMemo } from 'react';

interface Props extends Omit<SelectProps, 'value' | 'onChange' | 'options'> {
    value?: PartnerSpecialization;
    onChange?: (newValue: PartnerSpecialization | undefined) => void;
}

const SpecializationSelect: React.FC<Props> = ({ value, onChange, ...rest }) => {
    const specializations = useAppConfig().brand.partner.specializations;
    const options = useMemo(() => specializations.map(({ key, title }) => ({ key, label: title })), [specializations]);

    return (
        <Select
            options={options}
            value={options.find(v => v.key === value?.key)}
            onChange={v => onChange?.(specializations.find(s => s.key === v?.key) ?? undefined)}
            {...rest}
        />
    );
};

export default SpecializationSelect;
